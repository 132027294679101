import { React, useState } from 'react';
import { Dialog } from '@mui/material';
import axios from 'axios';
import { toast } from 'react-toastify';
import { ButtonComponent } from 'component/Button';
import { ReactComponent as CancelSvg } from 'assets/images/icons/close_24px.svg';
import { ReactComponent as DownloadSvg } from 'assets/images/icons/file_download.svg';
import { ReactComponent as AddSvg } from 'assets/images/icons/add_24px.svg';
import { ReactComponent as ShedulleSvg } from 'assets/images/icons/Shedulle.svg';
import { InputComponent } from 'component/Input';
import { SelectComponent } from 'component/Select';
import { axiosWrapper } from 'utils/Helper';
import {
  EOREBlocks,
  EORETemplate,
  HABlocks, HATemplate, NTSBlocks, NTSTemplate,
} from 'consts/Templates';
import 'assets/styles/pages/templates.scss';
import { useUser } from 'routes/UserContext';
import { NTSAttributesBlock } from './NTSAttributes';
import { HAAttributesBlock } from './HAAtrributes';
import { EoreAttributesBlock } from './EoreAttributes';

export const NewTemplate = (props) => {
  const [open, setOpen] = useState(false);
  const [reportName, setReportName] = useState(null);

  const [selectedNtsBlocks, setSelectedNtsBlocks] = useState(JSON.parse(JSON.stringify(NTSBlocks)));
  const [selectedHaBlocks, setSelectedHaBlocks] = useState(JSON.parse(JSON.stringify(HABlocks)));
  const [selectedEoreBlocks, setSelectedEoreBlocks] = useState(JSON.parse(JSON.stringify(EOREBlocks)));

  const [selectedNtsAttr, setSelectedNtsAttr] = useState(JSON.parse(JSON.stringify(NTSTemplate)));
  const [selectedHaAttr, setSelectedHaAttr] = useState(JSON.parse(JSON.stringify(HATemplate)));
  const [selectedEoreAttr, setSelectedEoreAttr] = useState(JSON.parse(JSON.stringify(EORETemplate)));

  const { user } = useUser();

  const reportTypeOptions = [
    { value: 'nts', label: 'NTS' },
    { value: 'ha', label: 'HA' },
    { value: 'eore', label: 'EORE' },
  ];
  const [reportType, setReportType] = useState(reportTypeOptions[0]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedHaBlocks(JSON.parse(JSON.stringify(HABlocks)));
    setSelectedNtsBlocks(JSON.parse(JSON.stringify(NTSBlocks)));
    setSelectedNtsAttr(JSON.parse(JSON.stringify(NTSTemplate)));
    setSelectedHaAttr(JSON.parse(JSON.stringify(HATemplate)));
    setSelectedEoreBlocks(JSON.parse(JSON.stringify(EOREBlocks)));
    setSelectedEoreAttr(JSON.parse(JSON.stringify(EORETemplate)));

    setReportName(null);
    setReportType(reportTypeOptions[0]);
  };

  const uploadNewTemplate = () => {
    let fields;
    if (!reportName) {
      toast.warning('You need to set template name');
      return;
    }
    if (reportType.value === 'nts') {
      fields = selectedNtsAttr;
    } else if (reportType.value === 'ha') {
      fields = selectedHaAttr;
    } else if (reportType.value === 'eore') {
      fields = selectedEoreAttr;
    }
    props.setLoader(true);
    axiosWrapper(() => {
      axios.post('/templates/', {
        fields,
        name: reportName,
        type: reportType.value,
      })
        .then((res) => {
          if (res.status === 201) {
            handleClose();
            toast.success('Template was created');
            props.getTemplates(1);
          }
          props.setLoader(false);
        })
        .catch((err) => {
          console.log(err);
          handleClose();
          toast.error('Error creating template.');
          props.setLoader(false);
        });
    });
  };

  const findKey = (obj, targetKey, blocks) => {
    if (blocks.hasOwnProperty(targetKey)) {
      return blocks[targetKey];
    }
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        if (key === targetKey) {
          return obj[key];
        } if (typeof obj[key] === 'object') {
          const result = findKey(obj[key], targetKey, blocks);
          if (result !== undefined) {
            return result;
          }
        }
      }
    }
    return false;
  };

  const handleTemplateBlockCheckbox = (block, attribute, isChecked) => {
    if (reportType.value === 'nts') {
      setSelectedNtsBlocks((prevSelect) => ({
        ...prevSelect,
        [block]: isChecked,
      }));
      if (selectedNtsBlocks.hasOwnProperty(block)) {
        // eslint-disable-next-line no-use-before-define
        setSelectedNtsAttr(updateBlockToBool(block, selectedNtsAttr, isChecked));
      }
    } else if (reportType.value === 'ha') {
      if (selectedHaBlocks.hasOwnProperty(block) && typeof (block) !== 'object') {
        setSelectedHaBlocks((prevSelect) => ({
          ...prevSelect,
          [block]: isChecked,
        }));
        // eslint-disable-next-line no-use-before-define
        setSelectedHaAttr(updateBlockToBool(block, selectedHaAttr, isChecked));
      }
    } else if (reportType.value === 'eore') {
      if (selectedEoreBlocks.hasOwnProperty(block) && typeof (block) !== 'object') {
        setSelectedEoreBlocks((prevSelect) => ({
          ...prevSelect,
          [block]: isChecked,
        }));
        setSelectedEoreAttr(updateBlockToBool(block, selectedEoreAttr, isChecked));
      }
    }
  };

  const updateBlockToBool = (block, template, bool) => {
    if (template[block]) {
      for (const key in template[block]) {
        if (template[block].hasOwnProperty(key)) {
          if (typeof template[block][key] === 'boolean') {
            template[block][key] = bool;
          }
          if (typeof template[block][key] === 'object') {
            handleTemplateBlockCheckbox(key, null, bool);
          }
        }
      }
    }
    return { ...template };
  };

  const handleAttributeCheckbox = (template, blocks, key, bool) => {
    const blockNames = key.split('__');
    if (reportType.value === 'ha') {
      if (blockNames[2]
        && !blocks.hasOwnProperty(blockNames[2])
        && typeof (template[blockNames[0]][blockNames[1]][blockNames[2]]) !== 'object') {
        setSelectedHaAttr((prevAttr) => ({
          ...prevAttr,
          [blockNames[0]]: {
            ...prevAttr[blockNames[0]],
            [blockNames[1]]: {
              ...prevAttr[blockNames[0]][blockNames[1]],
              [blockNames[2]]: bool,
            },
          },
        }));
        return;
      }
      if (blockNames[1]
        && typeof (template[blockNames[0]][blockNames[1]]) !== 'object') {
        setSelectedHaAttr((prevAttr) => ({
          ...prevAttr,
          [blockNames[0]]: {
            ...prevAttr[blockNames[0]],
            [blockNames[1]]: bool,
          },
        }));
        return;
      }
      if (blockNames[0]
        && !blocks.hasOwnProperty(blockNames[0])
        && typeof (template[blockNames[0]]) !== 'object') {
        setSelectedHaAttr((prevAttr) => ({
          ...prevAttr,
          [blockNames[0]]: bool,
        }));
        return;
      }
    }
    if (reportType.value === 'nts') {
      if (blockNames[1]
        && !blocks.hasOwnProperty(blockNames[1])
        && typeof (template[blockNames[1]]) !== 'object') {
        setSelectedNtsAttr((prevAttr) => ({
          ...prevAttr,
          [blockNames[0]]: {
            ...prevAttr[blockNames[0]],
            [blockNames[1]]: bool,
          },
        }));
        return;
      }
      if (blockNames[0]
        && !blocks.hasOwnProperty(blockNames[0])
        && typeof (template[blockNames[0]]) !== 'object') {
        setSelectedNtsAttr((prevAttr) => ({
          ...prevAttr,
          [blockNames[0]]: bool,
        }));
      }
    }
    if (reportType.value === 'eore') {
      if (blockNames[2]
        && !blocks.hasOwnProperty(blockNames[2])
        && typeof (template[blockNames[0]][blockNames[1]][blockNames[2]]) !== 'object') {
        setSelectedEoreAttr((prevAttr) => ({
          ...prevAttr,
          [blockNames[0]]: {
            ...prevAttr[blockNames[0]],
            [blockNames[1]]: {
              ...prevAttr[blockNames[0]][blockNames[1]],
              [blockNames[2]]: bool,
            },
          },
        }));
        return;
      }
      if (blockNames[1]
        && typeof (template[blockNames[0]][blockNames[1]]) !== 'object') {
        setSelectedEoreAttr((prevAttr) => ({
          ...prevAttr,
          [blockNames[0]]: {
            ...prevAttr[blockNames[0]],
            [blockNames[1]]: bool,
          },
        }));
        return;
      }
      if (blockNames[0]
        && !blocks.hasOwnProperty(blockNames[0])
        && typeof (template[blockNames[0]]) !== 'object') {
        setSelectedEoreAttr((prevAttr) => ({
          ...prevAttr,
          [blockNames[0]]: bool,
        }));
        return;
      }
    }
  };

  return (
    user?.permissions?.add_templates
    && (
      <div className="drone-new-btn">
        <ButtonComponent
          iconLeft={<DownloadSvg />}
          handleButton={handleClickOpen}
          label="Add template"
          className=""
        />
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          maxWidth="lg"
          className="templates-modal"
        >
          <div className="confirm-popup popup">
            <div className="header">
              <span className="title">Add template</span>
              <span className="closeBtn" onClick={handleClose} />
            </div>

            <div className="flex-column-group">
              <div className="flex-group">
                <InputComponent
                  id="template-name"
                  name="template-name"
                  iconLeft={<ShedulleSvg />}
                  value={reportName}
                  handleInput={setReportName}
                  label="Template name"
                  classNameWrapper="align-left"
                  placeholder="Enter name"
                />
                <div className="label-input w-100">
                  <span>Report type</span>
                  <SelectComponent
                    id="drone-report"
                    name="drone-report"
                    label="Report"
                    className="full"
                    placeholder={(
                      <div>
                        <ShedulleSvg />
                        <span>Select report type</span>
                      </div>
                    )}
                    value={reportType?.value
                      ? { value: reportType.value, label: reportType.label }
                      : null}
                    handleSelect={(value, label) => {
                      setReportType({ value, label });
                    }}
                    options={reportTypeOptions}
                    isMulti={false}
                    isDisabled={false}
                  />
                </div>
              </div>
              <div className="label-input">
                <span>Attributes</span>
                <div className="template-attributes">
                  {reportType?.value === 'nts'
                    && (
                      <NTSAttributesBlock
                        reportType={reportType}
                        selectedNtsBlocks={selectedNtsBlocks}
                        findKey={findKey}
                        handleTemplateBlockCheckbox={handleTemplateBlockCheckbox}
                        selectedNtsAttr={selectedNtsAttr}
                        handleTemplateCheckbox={handleAttributeCheckbox}
                        NTSAttributes={props.NTSAttributes}
                      />
                    )}
                  {reportType?.value === 'ha'
                    && (
                      <HAAttributesBlock
                        reportType={reportType}
                        selectedHaBlocks={selectedHaBlocks}
                        findKey={findKey}
                        handleTemplateBlockCheckbox={handleTemplateBlockCheckbox}
                        selectedHaAttr={selectedHaAttr}
                        handleTemplateCheckbox={handleAttributeCheckbox}
                        HAAttributes={props.HAAttributes}
                      />
                    )}
                  {reportType?.value === 'eore'
                    && (
                      <EoreAttributesBlock
                        reportType={reportType}
                        selectedEoreBlocks={selectedEoreBlocks}
                        findKey={findKey}
                        handleTemplateBlockCheckbox={handleTemplateBlockCheckbox}
                        selectedEoreAttr={selectedEoreAttr}
                        handleTemplateCheckbox={handleAttributeCheckbox}
                        EoreAttributes={props.EoreAttributes}
                      />
                    )}
                </div>
              </div>

            </div>
            <div className="buttons-group">
              <ButtonComponent
                className="danger"
                iconLeft={<CancelSvg />}
                handleButton={() => {
                  handleClose();
                }}
                label="Cancel"
              />
              <ButtonComponent
                iconLeft={<AddSvg />}
                handleButton={uploadNewTemplate}
                label="Add template"
              />
            </div>
          </div>
        </Dialog>
      </div>
    )
  );
};
