import React from 'react';
import { TranslateWrapperComponent } from 'component/Translation';
import { Accordion } from 'react-accessible-accordion';
import { ReactComponent as ChatIcon } from 'assets/images/icons/chat.svg';
import { SessionInfo } from '../Blocks/View/SessionInfo/SessionInfo';
import GeneralInfo from '../Blocks/View/GeneralInfo/GeneralInfo';
import { FinalIndicatorsView } from '../Blocks/View/FinalIndicators/FinalIndicators';
import PhotosBlockView from '../Blocks/View/PhotosBlockView/PhotosBlockView';
import AttachmentsBlockView from '../Blocks/View/AttachmentsBlockView/AttachmentsBlockView';

const CheckEORETab = ({
  formData,
  comments,
  zone,
  setZone,
  handouts,
  photos,
  attachments,
  setLoader,
  filteredFinalIndicatorsComments,
  filteredGeneralComments,
  filteredSessionInformationComments,
}) => (
  <>
    <div className="info_block_header">
      <div className="info_block_header_left">Атрибути</div>
      <span className="info_block_header_line" />
      <div className="info_block_header_right">Attributes</div>
    </div>
    {/* <TranslateWrapperComponent
      formData={formData}
    // setTranslatedFormData={setTranslatedFormData}
    // translationState={translateState}
    > */}
    <Accordion
      allowMultipleExpanded
      allowZeroExpanded
      preExpanded={[
        'general',
        'sessionInformation',
        'finalIndicators',
      ]}
      className="custom-accordion"
    >
      <GeneralInfo
        formData={formData}
        mode={'check'}
      />
      {filteredGeneralComments?.map((item, index) => {
        const isLastComment = index === 0;
        const commentClassName = isLastComment
          ? 'block-with-comment-green'
          : 'block-with-comment-gray';

        return (
          <div key={index} className={commentClassName}>
            <div className="icon">
              <ChatIcon
                style={{
                  width: '20px',
                  color: '#868686',
                }}
              />
            </div>
            {item?.comments?.general}
          </div>
        );
      })}
      <SessionInfo
        formData={formData}
        comments={comments}
        handouts={handouts}
        zone={zone}
        setZone={setZone}
        mode={'check'}
      />
      {filteredSessionInformationComments?.map((item, index) => {
        const isLastComment = index === 0;
        const commentClassName = isLastComment
          ? 'block-with-comment-green'
          : 'block-with-comment-gray';

        return (
          <div key={index} className={commentClassName}>
            <div className="icon">
              <ChatIcon
                style={{
                  width: '20px',
                  color: '#868686',
                }}
              />
            </div>
            {item?.comments?.session_information}
          </div>
        );
      })}
      <FinalIndicatorsView
        formData={formData}
      />
      {filteredFinalIndicatorsComments?.map((item, index) => {
        const isLastComment = index === 0;
        const commentClassName = isLastComment
          ? 'block-with-comment-green'
          : 'block-with-comment-gray';

        return (
          <div key={index} className={commentClassName}>
            <div className="icon">
              <ChatIcon
                style={{
                  width: '20px',
                  color: '#868686',
                }}
              />
            </div>
            {item?.comments?.final_indicators}
          </div>
        );
      })}
      <PhotosBlockView
        formData={formData}
        photos={photos}
        setLoader={setLoader}
      />
      <AttachmentsBlockView
        attachments={attachments}
        setLoader={setLoader}
        formData={formData}
      />
    </Accordion>
    {/* </TranslateWrapperComponent> */}
  </>
);

export default CheckEORETab;
