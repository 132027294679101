import React from 'react';
import { Accordion, AccordionItem } from 'react-accessible-accordion';
import { FileUploadMultiple } from 'component/FileUploadMultiple';
import { convert3857To4326 } from 'utils/Helper';
import { ReactComponent as FileDownloadIcon } from 'assets/images/icons/file_download.svg';
import { Card } from '../../../component/Card';
import { FileInformation } from '../blocks/FileInformation';
import { EvidenceInformation } from '../blocks/EvidenceInformation';
import { MapInfo } from '../blocks/MapInfo';

export const GisTab = (props) => {
  const { formData } = props;
  const { setFormData } = props;

  const handleFileInformation = (data, files, zone) => {
    if (!data?.turn_points) return;
    //data.turn_points.geojson = convert3857To4326(data.turn_points.geojson, zone);

    const turnPointsFile = files.filter((file) => file[0] === 'turn_points')[0]?.[1];
    props.setFormData((prevFormData) => ({
      ...prevFormData,
      info: {
        ...prevFormData.info,
        turn_points: data.turn_points,
        files: {
          ...prevFormData.info.files,
          turn_points: turnPointsFile.name,
        },
      },
    }));
    props.setFilesNames((prevData) => ({
      ...prevData,
      turn_points: turnPointsFile.name,
    }));
    props.setFiles((prevData) => ({
      ...prevData,
      turn_points: turnPointsFile,
    }));
  };

  const newEvidencePoints = (items, data) => {
    const evidences = data.geojson?.features?.map((feature) => {
      const lng = feature.geometry.coordinates[0];
      const lat = feature.geometry.coordinates[1];
      return {
        evidence_type: '',
        long: lng,
        lat,
        description: '',
        short_evidences_description: '',
        photo: [],
      };
    });
    // evidences.unshift(...items);
    return evidences;
  };

  const handleEvidenceInformation = (data, files, zone) => {
    if (!data && !data.evidence) return;
    const evidenceFile = files.filter((file) => file[0] === 'evidence')[0]?.[1];
    props.setFormData((prevFormData) => ({
      ...prevFormData,
      info: {
        ...prevFormData.info,
        evidences: {
          ...prevFormData.info.evidences,
          direct_evidences: {
            ...prevFormData.info.evidences.direct_evidences,
            items: newEvidencePoints(prevFormData.info
              .evidences.direct_evidences.items, data.evidence),
          },
          evidence_geojson: convert3857To4326(data.evidence.geojson, zone),
        },
        files: {
          ...prevFormData.info.files,
          evidence: evidenceFile.name,
        },
      },
    }));
    props.setFilesNames((prevData) => ({
      ...prevData,
      evidence: evidenceFile.name,
    }));
    props.setFiles((prevData) => ({
      ...prevData,
      evidence: evidenceFile,
    }));
  };

  const handleLmBmInformation = (data, files) => {
    if (!data && !data.lm_bm) return;
    const lmBmFile = files.filter((file) => file[0] === 'lm_bm')[0]?.[1];

    props.setFormData((prevFormData) => ({
      ...prevFormData,
      info: {
        ...prevFormData.info,
        lm: {
          ...prevFormData.info.lm,
          geometry: data.lm_bm.LM.geojson.features[0]?.geometry,
        },
        bm: {
          ...prevFormData.info.bm,
          geometry: data.lm_bm.BM.geojson.features[0]?.geometry,
        },
        files: {
          ...prevFormData.info.files,
          lm_bm: lmBmFile.name,
        },
        turn_points: data.turn_points || prevFormData.info.turn_points,
      },
    }));
    props.setFilesNames((prevData) => ({
      ...prevData,
      lm_bm: lmBmFile.name,
    }));
    props.setFiles((prevData) => ({
      ...prevData,
      lm_bm: lmBmFile,
    }));
  };

  return (
    <div className="form-wrapper">
      <div className="header">
        <span>ГІС</span>
        <span className="line" />
        <span
          className="text-hover no-wrap"
          onClick={() => props.handleTabChange(0)}
        >
          Back to edit
          <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M9 16H19V3M19 3L22 6M19 3L16 6M15 11H5V22M5 22L8 19M5 22L2 19"
              stroke="#868686"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </span>
      </div>

      <div className="download-cards flex-group flex-center">
        <Card
          header={(
            <>
              <div className="icon-wrapper">
                <FileDownloadIcon />
              </div>
              <span>LM/BM</span>

            </>
          )}
          body={(
            <FileUploadMultiple
              id="lm_bm"
              type="lm_bm"
              formData={formData}
              setFormData={setFormData}
              postFiles
              types=".zip"
              callback={handleLmBmInformation}
              fileName={formData.info.files.lm_bm}
              files={props.files}
            />
          )}
        />
        <Card
          header={(
            <>
              <div className="icon-wrapper">
                <FileDownloadIcon />
              </div>
              <span>Turn point</span>
            </>
          )}
          body={(
            <FileUploadMultiple
              id="turn_points"
              type="turn_points"
              formData={formData}
              setFormData={setFormData}
              postFiles
              types=".zip"
              callback={handleFileInformation}
              fileName={formData.info.files.turn_points}
              files={props.files}
            />
          )}
        />
        <Card
          header={(
            <>
              <div className="icon-wrapper">
                <FileDownloadIcon />
              </div>
              <span>Evidence</span>
            </>
          )}
          body={(
            <FileUploadMultiple
              id="evidence"
              type="evidence"
              formData={formData}
              setFormData={setFormData}
              postFiles
              types=".zip"
              callback={handleEvidenceInformation}
              fileName={formData.info.files.evidence}
              files={props.files}
            />
          )}
        />
      </div>
      {(props.errors?.lm?.photo
        || props.errors?.bm?.photo) && (
          <span className="error-text">
            Потрібно завантажити фото для LM і BM
          </span>
      )}
      <Accordion
        allowMultipleExpanded
        allowZeroExpanded
        preExpanded={['fileInfo', 'evidenceInfo', 'map']}
        className="custom-accordion"
      >
        <AccordionItem uuid="fileInfo">
          <FileInformation
            formData={formData}
            accordionId="1"
            tab="gis"
          />
        </AccordionItem>
        <AccordionItem uuid="evidenceInfo">
          <EvidenceInformation
            formData={formData}
            tab="gis"
            map={props.map}
            setMap={props.setMap}
          />
        </AccordionItem>
        <AccordionItem uuid="map">
          <MapInfo
            map={props.map}
            setMap={props.setMap}
            formData={formData}
          />
        </AccordionItem>
      </Accordion>
    </div>

  );
};
