/* eslint-disable prefer-promise-reject-errors */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-shadow */
/* eslint-disable no-alert */
import { React, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { Loader } from 'component/Loader';
import { ToastContainer, toast } from 'react-toastify';

import { ReactComponent as ArrowIcon } from 'assets/images/icons/arrow_left.svg';
import { ReactComponent as ChatIcon } from 'assets/images/icons/chat.svg';
import { ReactComponent as MapIcon } from 'assets/images/icons/map3.svg';
import { ReactComponent as FilesIcon } from 'assets/images/icons/Files.svg';
import { ReactComponent as TranslateSvg } from 'assets/images/icons/translate_24px.svg';
import { TranslateWrapperComponent } from 'component/Translation';
import { getHAById } from 'api/HaApi';
import axios from 'axios';

import 'assets/styles/tabs.scss';
import { axiosWrapper } from 'utils/Helper';

import 'react-tabs/style/react-tabs.css';
import '../View/styles.scss';

import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
} from 'react-accessible-accordion';

import { AccordionHeaderTitle } from 'component/Accordion';

import { InputComponent } from 'component/Input';
import { ButtonComponent } from 'component/Button';

import { getPhotoByName } from 'api/ImagesApi';
import { ReactComponent as DeleteIcon } from 'assets/images/icons/delete_24px.svg';
import { CommentsFromImsmaView, CommentsView } from '../ViewBlocks/Comments';
import { HAformData } from '../../../consts/Forms';
import { FileInformation } from '../blocks/FileInformation';
import { MapInfo } from '../blocks/MapInfo';
import { GeneralInfoView } from '../ViewBlocks/GeneralInfo/GeneralInfo';
import { LocationView } from '../ViewBlocks/Location/Location';
import { ContaminationView } from '../ViewBlocks/Contamination/Contamination';
import { EvidencesView } from '../ViewBlocks/Evidences/Evidences';
import { FileInfoView } from '../ViewBlocks/FileInfo/FileInfo';
import { GeographicView } from '../ViewBlocks/Geographic/Geographic';
import { LandRelease } from '../ViewBlocks/LandRelease/LandRelease';
import { BeneficiariesView } from '../ViewBlocks/Beneficiaries/Beneficiaries';
import { InformatorsView } from '../ViewBlocks/Informators/Informators';
import { PhotosView } from '../ViewBlocks/Photos/Photos';
import { AnnexMapView } from '../ViewBlocks/AnnexMap/AnnexMap';

const ValidationHAReportPage = (props) => {
  const [formData, setFormData] = useState(HAformData);
  const [loader, setLoader] = useState(false);
  const [map, setMap] = useState(null);
  const [annexImages, setAnnexImages] = useState([]);
  const [photos, setPhotos] = useState([]);
  const [rejectButtonDisabled, setRejectButtonDisabled] = useState(true);
  const [translatedFormData, setTranslatedFormData] = useState([]);
  const [translateState, setTranslateState] = useState(false);

  const location = useLocation();
  const navigate = useNavigate();

  const [commentForm, setCommentForm] = useState({
    files: '',
    lm: '',
    bm: '',
    turn_points: '',
    general: '',
    location: '',
    hazard_type: '',
    evidences: '',
    contamination: '',
    polygon_info: '',
    geographic_and_terrain_description: '',
    next_landrelease_activity: '',
    landuse_and_beneficiaries: '',
    informators: '',
    map: '',
  });

  const [commentEntered, setCommentEntered] = useState({
    general: false,
    location: false,
    files: false,
    lm: false,
    bm: false,
    contamination: false,
    turn_points: false,
    hazard_type: false,
    evidences: false,
    polygon_info: false,
    geographic_and_terrain_description: false,
    next_landrelease_activity: false,
    landuse_and_beneficiaries: false,
    informators: false,
    map: false,
  });
  const [savedComment, setSavedComment] = useState(commentForm);
  const [comments, setComments] = useState([]);

  const onDeleteComment = (event, block) => {
    event.preventDefault();

    const confirmDelete = window.confirm(
      'Are you sure you want to delete this comment?',
    );

    if (confirmDelete) {
      setSavedComment((prevComment) => ({
        ...prevComment,
        [block]: '',
      }));
    }
    setCommentEntered((prevState) => ({
      ...prevState,
      [block]: false,
    }));
  };

  useEffect(() => {
    document.title = `Validation HA report - ${formData?.info.general.hazard_name || 'Unknow'}`;
  }, [formData]);

  const [mode, setMode] = useState(null);

  useEffect(() => {
    const savedMode = localStorage.getItem('mode');
    setMode(savedMode);

    localStorage.removeItem('mode');
  }, []);

  const selectedHaReportId = window.location.pathname.split('/')[2];
  const isValidationMode = mode === 'validation';

  const getAnnexImage = (reportId, imageFile) => {
    if (!imageFile || !imageFile.file_name) {
      return Promise.resolve();
    }
    return new Promise((resolve, reject) => {
      axiosWrapper(() => {
        getPhotoByName(reportId, imageFile.file_name, true)
          .then((res) => {
            if (res.status === 200) {
              const image = new File([res.data], imageFile.file_name, {
                type: 'image/jpeg',
              });

              setAnnexImages((prevPhotos) => [
                ...prevPhotos,
                {
                  file: image,
                  data_url: URL.createObjectURL(res.data),
                  file_name: imageFile.file_name,
                  is_main: imageFile.is_main,
                },
              ]);
              resolve();
            } else {
              reject('Annex maps request failed');
            }
          })
          .catch((err) => {
            toast.error('Annex maps not loaded');
            reject(err);
          });
      });
    });
  };

  const handleComments = (block, value) => {
    setCommentForm((prevForm) => ({
      ...prevForm,
      [block]: value,
    }));
  };

  const getAllComments = () => {
    axiosWrapper(() => {
      axios.get(`/validations/ha/${selectedHaReportId}`).then((data) => {
        const allComments = data.data.iterations;
        setComments(allComments);
      });
    });
  };

  const getPhoto = (reportId, imageFile, dependency, item) => {
    if (!imageFile || !imageFile.file_name) {
      return Promise.resolve();
    }

    return new Promise((resolve, reject) => {
      axiosWrapper(() => {
        getPhotoByName(reportId, imageFile.file_name)
          .then((res) => {
            if (res.status === 200) {
              const image = new File([res.data], imageFile.file_name, {
                type: 'image/jpeg',
              });

              setPhotos((prevPhotos) => [
                ...prevPhotos,
                {
                  file: image,
                  data_url: URL.createObjectURL(res.data),
                  name: imageFile.file_name,
                  dependency,
                  item,
                  description: imageFile.description || '',
                  lat: imageFile.lat || '',
                  long: imageFile.long || '',
                  date: imageFile.date || '',
                },
              ]);

              resolve();
            } else {
              reject('Photo request failed');
            }
          })
          .catch((err) => {
            toast.error(
              `Photo with file name ${imageFile.file_name} not loaded`,
            );
            reject(err);
          });
      });
    });
  };
  const getAllPhotos = (res) => new Promise((resolve) => {
    const promises = [];

    res.data.info.general_photos.items?.forEach((item) => {
      promises.push(getPhoto(res.data.id, item, 'general', null));
    });

    res.data.info.evidences.direct_evidences.items?.forEach((item, index) => {
      if (!item.photo) return;
      item.photo?.forEach((photo) => {
        promises.push(
          getPhoto(res.data.id, photo, 'direct_evidence', index + 1),
        );
      });
    });

    res.data.info.evidences.indirect_evidences.items?.forEach(
      (item, index) => {
        if (!item.photo) return;
        item.photo?.forEach((photo) => {
          promises.push(
            getPhoto(res.data.id, photo, 'indirect_evidence', index + 1),
          );
        });
      },
    );

    if (res.data.info.lm.photo.length > 0) {
      res.data.info.lm.photo.forEach((photo) => {
        promises.push(getPhoto(res.data.id, photo, 'lm', null));
      });
      res.data.info.bm.photo.forEach((photo) => {
        promises.push(getPhoto(res.data.id, photo, 'bm', null));
      });
    } else {
      promises.push(getPhoto(res.data.id, res.data.info.lm.photo, 'lm', null));
      promises.push(getPhoto(res.data.id, res.data.info.bm.photo, 'bm', null));
    }

    Promise.all(promises)
      .then(() => {
        resolve('ok');
      })
      .catch(() => {
        toast.error('Photos not loaded');
      });
  });

  const handleValidationAcceptButton = (status, event) => {
    setCommentForm({
      files: '',
      lm: '',
      bm: '',
      turn_points: '',
      general: '',
      location: '',
      contamination: '',
      hazard_type: '',
      evidences: '',
      polygon_info: '',
      geographic_and_terrain_description: '',
      next_landrelease_activity: '',
      landuse_and_beneficiaries: '',
      informators: '',
      map: '',
    });
    event.preventDefault();
    setLoader(true);
    axiosWrapper(() => {
      axios
        .put(`/validations/ha/${selectedHaReportId}/`, {
          status,
          comments: savedComment,
        })
        .then(() => {
          toast.success('Accepted');
          setLoader(false);
          setTimeout(() => {
            navigate('/nts-ha-report');
          }, 1000);
        })
        .catch((err) => {
          if (err?.response?.status === 400) {
            toast.error(err?.response?.data.error);
          }
          if (err?.response?.status === 500) {
            toast.error('Error').then(() => toast.success('Please try again'));
          }
          toast.error('Error accepting HA report. Please try again.');
          setLoader(false);
        });
    });
  };

  const handleValidationRejectButton = (status, event) => {
    setLoader(true);
    event.preventDefault();
    axiosWrapper(() => {
      axios
        .put(`/validations/ha/${selectedHaReportId}/`, {
          status,
          comments: savedComment,
        })
        .then(() => {
          toast.success('Rejected');
          setLoader(false);
          setTimeout(() => {
            navigate('/nts-ha-report');
          }, 1000);
        })
        .catch((err) => {
          if (err?.response?.status === 400) {
            toast.error(err?.response?.data.error);
          }
          if (err?.response?.status === 500) {
            toast.error('Error').then(() => toast.success('Please try again'));
          }
          toast.error('Error rejected HA report. Please try again.');
          setLoader(false);
        });
    });
  };
  const onSaveComment = (event, block) => {
    const comment = commentForm[block]?.trim();

    if (comment) {
      setSavedComment((prevComment) => ({
        ...prevComment,
        [block]: comment,
      }));

      setCommentEntered((prevState) => ({
        ...prevState,
        [block]: true,
      }));

      setCommentForm((prevForm) => ({
        ...prevForm,
        [block]: '',
      }));

      const commentsCount = Object.keys(savedComment)?.length;
      setRejectButtonDisabled(commentsCount === 0);
    } else {
      setCommentEntered((prevState) => ({
        ...prevState,
        [block]: false,
      }));
    }
  };

  const getAnnexImages = (res) => new Promise((resolve) => {
    const promises = [];

    res.data.info.annex?.items?.forEach((item) => {
      promises.push(getAnnexImage(res.data.id, item));
    });

    Promise.all(promises)
      .then(() => {
        resolve('ok');
      })
      .catch(() => {
        toast.error('Annex maps not loaded');
      });
  });

  const PageTitle = (props) => (
    <div className="page-title">
      <ArrowIcon
        onClick={() => props.navigate(-1)}
        style={{ cursor: 'pointer' }}
      />
      <div>{props.title}</div>
      <span className="blue-text">
        |
        {props.name}
      </span>
    </div>
  );

  useEffect(() => {
    getAllComments();
    if (!selectedHaReportId) return;
    getHAById(selectedHaReportId)
      .then((res) => {
        if (res.status === 200) {
          setFormData(res.data);
          getAnnexImages(res);
          getAllPhotos(res);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (

    <section>
      <PageTitle
        title="Validation"
        name={formData.info?.general.hazard_name || 'Unknown'}
        navigate={navigate}
      />
      <TranslateWrapperComponent
        formData={formData}
        setTranslatedFormData={setTranslatedFormData}
        translationState={translateState}
      >
        <form className="form-wrapper">
          <div
            className="text-hover flex-group flex-right"
            onClick={() => setTranslateState(!translateState)}
          >
            <TranslateSvg />
            <span>Translate</span>
          </div>
          <div className="info_block_header">
            <div className="info_block_header_left">Атрибути</div>
            <span className="info_block_header_line" />
            <div className="info_block_header_right">Attributes</div>
          </div>

          <Accordion
            allowMultipleExpanded
            allowZeroExpanded
            preExpanded={[
              'general',
              'location',
              'contamination',
              'evidence',
              'fileInfo',
              'geographic',
              'landrelease',
              'beneficiaries',
              'informators',
            ]}
            className="custom-accordion"
          >
            <CommentsFromImsmaView comments={comments} />
            <>
              <GeneralInfoView
                formData={formData}
                comments={comments}
                translatedFormData={translatedFormData}
                translateState={translateState}
              />

              <div className="comment-view-block">
                {savedComment?.general && (
                  <div className="block-with-comment">
                    <div className="icon-with-comment">
                      <ChatIcon style={{ width: '20px' }} />
                      <div className="comment">
                        {savedComment?.general}
                      </div>
                    </div>
                    <div className="comment-buttons">
                      <DeleteIcon
                        className="delete-comment-button"
                        style={{ cursor: 'pointer' }}
                        onClick={(e) => onDeleteComment(e, 'general')}
                      />
                    </div>
                  </div>
                )}
                {!commentEntered.general && (
                  <div className="input-with-button">
                    <InputComponent
                      classNameWrapper="w-100"
                      style={{ width: '-webkit-fill-available' }}
                      className="input-view"
                      handleInput={(value) => handleComments('general', value)}
                      onBlur={(e) => onSaveComment(e, 'general')}
                      value={commentForm?.general}
                      placeholder="Add comment"
                    />
                  </div>
                )}
              </div>
            </>
            <>
              <LocationView
                formData={formData}
                comments={comments}
                translatedFormData={translatedFormData}
                translateState={translateState}
              />

              <div className="comment-view-block">
                {savedComment?.location && (
                  <div className="block-with-comment">
                    <div className="icon-with-comment">
                      <ChatIcon style={{ width: '20px' }} />
                      <div className="comment">
                        {savedComment?.location}
                      </div>
                    </div>
                    <div className="comment-buttons">
                      <DeleteIcon
                        className="delete-comment-button"
                        style={{ cursor: 'pointer' }}
                        onClick={(e) => onDeleteComment(e, 'location')}
                      />
                    </div>
                  </div>
                )}
                {!commentEntered.location && (
                  <div className="input-with-button">
                    <InputComponent
                      classNameWrapper="w-100"
                      style={{ width: '-webkit-fill-available' }}
                      className="input-view"
                      handleInput={(value) => handleComments('location', value)}
                      onBlur={(e) => onSaveComment(e, 'location')}
                      value={commentForm?.location}
                      placeholder="Add comment"
                    />
                  </div>
                )}
              </div>
            </>
            <>
              <ContaminationView
                formData={formData}
                comments={comments}
                translatedFormData={translatedFormData}
                translateState={translateState}
              />
              <div className="comment-view-block">
                {savedComment?.contamination && (
                  <div className="block-with-comment">
                    <div className="icon-with-comment">
                      <ChatIcon style={{ width: '20px' }} />
                      <div className="comment">
                        {savedComment?.contamination}
                      </div>
                    </div>
                    <div className="comment-buttons">
                      <DeleteIcon
                        className="delete-comment-button"
                        style={{ cursor: 'pointer' }}
                        onClick={(e) => onDeleteComment(e, 'contamination')}
                      />
                    </div>
                  </div>
                )}
                {!commentEntered.contamination && (
                  <div className="input-with-button">
                    <InputComponent
                      classNameWrapper="w-100"
                      style={{ width: '-webkit-fill-available' }}
                      className="input-view"
                      handleInput={(value) => handleComments('contamination', value)}
                      onBlur={(e) => onSaveComment(e, 'contamination')}
                      value={commentForm?.contamination}
                      placeholder="Add comment"
                    />
                  </div>
                )}
              </div>
            </>

            <>
              <EvidencesView
                formData={formData}
                comments={comments}
                map={map}
                setMap={setMap}
                translatedFormData={translatedFormData}
                translateState={translateState}
              />
              <div className="comment-view-block">
                {savedComment?.evidences && (
                  <div className="block-with-comment">
                    <div className="icon-with-comment">
                      <ChatIcon style={{ width: '20px' }} />
                      <div className="comment">
                        {savedComment?.evidences}
                      </div>
                    </div>
                    <div className="comment-buttons">
                      <DeleteIcon
                        className="delete-comment-button"
                        style={{ cursor: 'pointer' }}
                        onClick={(e) => onDeleteComment(e, 'evidences')}
                      />
                    </div>
                  </div>
                )}
                {!commentEntered.evidences && (
                  <div className="input-with-button">
                    <InputComponent
                      classNameWrapper="w-100"
                      style={{ width: '-webkit-fill-available' }}
                      className="input-view"
                      handleInput={(value) => handleComments('evidences', value)}
                      onBlur={(e) => onSaveComment(e, 'evidences')}
                      value={commentForm?.evidences}
                      placeholder="Add comment"
                    />
                  </div>
                )}
              </div>
            </>
            <>
              <FileInfoView
                formData={formData}
                comments={comments}
                map={map}
                setMap={setMap}
                translatedFormData={translatedFormData}
                translateState={translateState}
              />
              <div className="comment-view-block">
                {savedComment?.polygon_info && (
                  <div className="block-with-comment">
                    <div className="icon-with-comment">
                      <ChatIcon style={{ width: '20px' }} />
                      <div className="comment">
                        {savedComment?.polygon_info}
                      </div>
                    </div>
                    <div className="comment-buttons">
                      <DeleteIcon
                        className="delete-comment-button"
                        style={{ cursor: 'pointer' }}
                        onClick={(e) => onDeleteComment(e, 'polygon_info')}
                      />
                    </div>
                  </div>
                )}
                {!commentEntered.polygon_info && (
                  <div className="input-with-button">
                    <InputComponent
                      classNameWrapper="w-100"
                      style={{ width: '-webkit-fill-available' }}
                      className="input-view"
                      handleInput={(value) => handleComments('polygon_info', value)}
                      onBlur={(e) => onSaveComment(e, 'polygon_info')}
                      value={commentForm?.polygon_info}
                      placeholder="Add comment"
                    />
                  </div>
                )}
              </div>
            </>
            <>
              <GeographicView
                formData={formData}
                comments={comments}
                translatedFormData={translatedFormData}
                translateState={translateState}
              />
              <div className="comment-view-block">
                {savedComment?.geographic_and_terrain_description && (
                  <div className="block-with-comment">
                    <div className="icon-with-comment">
                      <ChatIcon style={{ width: '20px' }} />
                      <div className="comment">
                        {savedComment?.geographic_and_terrain_description}
                      </div>
                    </div>
                    <div className="comment-buttons">
                      <DeleteIcon
                        className="delete-comment-button"
                        style={{ cursor: 'pointer' }}
                        onClick={(e) => onDeleteComment(
                          e,
                          'geographic_and_terrain_description',
                        )}
                      />
                    </div>
                  </div>
                )}
                {!commentEntered.geographic_and_terrain_description && (
                  <div className="input-with-button">
                    <InputComponent
                      classNameWrapper="w-100"
                      style={{ width: '-webkit-fill-available' }}
                      className="input-view"
                      handleInput={(value) => handleComments('geographic_and_terrain_description', value)}
                      onBlur={(e) => onSaveComment(e, 'geographic_and_terrain_description')}
                      value={commentForm?.geographic_and_terrain_description}
                      placeholder="Add comment"
                    />
                  </div>
                )}
              </div>
            </>

            <>
              <LandRelease
                formData={formData}
                comments={comments}
                translatedFormData={translatedFormData}
                translateState={translateState}
              />
              <div className="comment-view-block">
                {savedComment?.next_landrelease_activity && (
                  <div className="block-with-comment">
                    <div className="icon-with-comment">
                      <ChatIcon style={{ width: '20px' }} />
                      <div className="comment">
                        {savedComment?.next_landrelease_activity}
                      </div>
                    </div>
                    <div className="comment-buttons">
                      <DeleteIcon
                        className="delete-comment-button"
                        style={{ cursor: 'pointer' }}
                        onClick={(e) => onDeleteComment(e, 'next_landrelease_activity')}
                      />
                    </div>
                  </div>
                )}
                {!commentEntered.next_landrelease_activity && (
                  <div className="input-with-button">
                    <InputComponent
                      classNameWrapper="w-100"
                      style={{ width: '-webkit-fill-available' }}
                      className="input-view"
                      handleInput={(value) => handleComments('next_landrelease_activity', value)}
                      onBlur={(e) => onSaveComment(e, 'next_landrelease_activity')}
                      value={commentForm?.next_landrelease_activity}
                      placeholder="Add comment"
                    />
                  </div>
                )}
              </div>
            </>

            <>
              <BeneficiariesView
                formData={formData}
                comments={comments}
              />
              <div className="comment-view-block">
                {savedComment?.landuse_and_beneficiaries && (
                  <div className="block-with-comment">
                    <div className="icon-with-comment">
                      <ChatIcon style={{ width: '20px' }} />
                      <div className="comment">
                        {savedComment?.landuse_and_beneficiaries}
                      </div>
                    </div>
                    <div className="comment-buttons">
                      <DeleteIcon
                        className="delete-comment-button"
                        style={{ cursor: 'pointer' }}
                        onClick={(e) => onDeleteComment(e, 'landuse_and_beneficiaries')}
                      />
                    </div>
                  </div>
                )}
                {!commentEntered.landuse_and_beneficiaries && (
                  <div className="input-with-button">
                    <InputComponent
                      classNameWrapper="w-100"
                      style={{ width: '-webkit-fill-available' }}
                      className="input-view"
                      handleInput={(value) => handleComments('landuse_and_beneficiaries', value)}
                      onBlur={(e) => onSaveComment(e, 'landuse_and_beneficiaries')}
                      value={commentForm?.landuse_and_beneficiaries}
                      placeholder="Add comment"
                    />
                  </div>
                )}
              </div>
            </>

            <>
              <InformatorsView
                formData={formData}
                comments={comments}
                translatedFormData={translatedFormData}
                translateState={translateState}
              />
              <div className="comment-view-block">
                {savedComment?.informators && (
                  <div className="block-with-comment">
                    <div className="icon-with-comment">
                      <ChatIcon style={{ width: '20px' }} />
                      <div className="comment">
                        {savedComment?.informators}
                      </div>
                    </div>
                    <div className="comment-buttons">
                      <DeleteIcon
                        className="delete-comment-button"
                        style={{ cursor: 'pointer' }}
                        onClick={(e) => onDeleteComment(e, 'informators')}
                      />
                    </div>
                  </div>
                )}
                {!commentEntered.informators && (
                  <div className="input-with-button">
                    <InputComponent
                      classNameWrapper="w-100"
                      style={{ width: '-webkit-fill-available' }}
                      className="input-view"
                      handleInput={(value) => handleComments('informators', value)}
                      onBlur={(e) => onSaveComment(e, 'informators')}
                      value={commentForm?.informators}
                      placeholder="Add comment"
                    />
                  </div>
                )}
              </div>
            </>

          </Accordion>
          <div className="info_block_header">
            <div className="info_block_header_left">ГІС</div>
            <span className="info_block_header_line" />
            <div className="info_block_header_right">Gis</div>
          </div>

          <Accordion
            allowMultipleExpanded
            allowZeroExpanded
            preExpanded={['fileInfo', 'evidenceInfo', 'map', 'annexMaps']}
            className="custom-accordion"
          >
            <AccordionItem uuid="fileInfo">
              <FileInformation
                formData={formData}
                accordionId={<FilesIcon style={{ color: '#00b1b7' }} />}
                tab="gis"
              />
            </AccordionItem>

            <AccordionItem uuid="map">
              <MapInfo
                formData={formData}
                accordionId={<MapIcon />}
                map={map}
                setMap={setMap}
                evidences={props?.formData?.info?.evidences}
              />
              <CommentsView
                comments={comments}
                block="map"
              />
              <div className="comment-view-block">
                {savedComment?.map && (
                  <div className="block-with-comment">
                    <div className="icon-with-comment">
                      <ChatIcon style={{ width: '20px' }} />
                      <div className="comment">{savedComment?.map}</div>
                    </div>
                    <div className="comment-buttons">
                      <DeleteIcon
                        className="delete-comment-button"
                        style={{ cursor: 'pointer' }}
                        onClick={(e) => onDeleteComment(e, 'map')}
                      />
                    </div>
                  </div>
                )}
                {!commentEntered.map && (
                  <div className="input-with-button">
                    <InputComponent
                      classNameWrapper="w-100"
                      style={{ width: '-webkit-fill-available' }}
                      className="input-view"
                      handleInput={(value) => handleComments('map', value)}
                      value={commentForm?.map}
                      placeholder="Add comment"
                      onBlur={(e) => onSaveComment(e, 'map')}
                    />
                  </div>
                )}
              </div>
            </AccordionItem>
          </Accordion>
          <div className="info_block_header">
            <div className="info_block_header_left">Фото</div>
            <span className="info_block_header_line" />
            <div className="info_block_header_right">Photos</div>
          </div>
          <Accordion
            className="custom-accordion"
            allowMultipleExpanded
            allowZeroExpanded
          >
            <AccordionItem uuid="photos">
              <AccordionItemHeading>
                <AccordionItemButton>
                  <AccordionHeaderTitle titleUA="Фото" titleEN="Photo" />
                </AccordionItemButton>
              </AccordionItemHeading>
              <PhotosView photos={photos} />
            </AccordionItem>
          </Accordion>
          <Accordion
            className="custom-accordion"
            allowMultipleExpanded
            allowZeroExpanded
          >
            <AccordionItem uuid="annexMaps">
              <AccordionItemHeading>
                <AccordionItemButton>
                  <AccordionHeaderTitle titleUA="Annex Maps" />
                </AccordionItemButton>
              </AccordionItemHeading>

              <AnnexMapView annexImages={annexImages} />
            </AccordionItem>
          </Accordion>
          {isValidationMode && (
            <div className="form-buttons buttons-group py-10">
              <ButtonComponent
                handleButton={(event) => handleValidationRejectButton('rejected', event)}
                label="Reject"
                disabled={rejectButtonDisabled}
                className="danger"
                type="button"
              />
              <ButtonComponent
                handleButton={(event) => handleValidationAcceptButton('accepted', event)}
                label="Accept"
                disabled={false}
                type="button"
              />
            </div>
          )}
        </form>
      </TranslateWrapperComponent>

      <Loader visible={loader} />
      <ToastContainer />
    </section>
  );
};

export default ValidationHAReportPage;
