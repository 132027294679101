import { Table, TableHeader, TableRow } from 'component/Table';
import React, { useState } from 'react';
import { useUser } from 'routes/UserContext';
import { Link } from 'react-router-dom';
import { ReactComponent as PencilSvg } from 'assets/images/icons/mode_edit_24px.svg';
import { ReactComponent as ViewSvg } from 'assets/images/icons/Eye-on.svg';
import { ReactComponent as CommentIcon } from 'assets/images/icons/chat.svg';
import { DeleteReportDialog } from 'component/Dialog';
import { deleteEoreReport } from 'api/EOREApi';
import { toast } from 'react-toastify';
import { exportEOREDocx, ValidationStatusComponent } from 'utils/Helper';
import { ReactComponent as ExportIcon } from 'assets/images/icons/export-icon.svg';

export const typeOfSessionOptions = (value) => {
  switch (value) {
    case 'face_to_face':
      return 'Взаємодія віч-на-віч';
    case 'online':
      return 'Онлайн сесії';
    case 'tot':
      return 'Навчання тренерів';
    case 'materials_distr':
      return 'Розповсюдження матеріалів';
    default:
      return 'Other';
  }
};

export const placeTypeOptions = (value) => {
  switch (value) {
    case 'educational_facility':
      return 'Школа/навчальний заклад';
    case 'organisation':
      return 'Підприємство/організація';
    case 'household_visit':
      return 'Візит в домогосподарство';
    case 'eecp':
      return 'EECP';
    case 'openair':
      return 'Сесія на відкритому повітрі';
    default:
      return 'Other';
  }
};

export const EOREReportsTable = (props) => (
  <div className="eore-table">
    <Table>
      <TableHeader>
        <th>Date</th>
        <th>Internal ID</th>
        <th>Place</th>
        <th>Type of session</th>
        <th>Total beneficiaries</th>
        <th>Status</th>
        <th>Action</th>
      </TableHeader>
      <tbody>
        {props.reportsData.length === 0
          && (
            <TableRow>
              <td>Дані відсутні</td>
            </TableRow>
          )}
        {props.reportsData.map((data) => (
          <TableRow key={data.id}>
            <td>{data.report_date}</td>
            <td>{data.internal_id || data.custom.internal_id || '-'}</td>
            <td>{placeTypeOptions(data.place_type_imsma) || '-'}</td>
            <td>{typeOfSessionOptions(data.eore_type_imsma) || '-'}</td>
            <td>{data.total_beneficiaries || '-'}</td>
            <td>
              {data.validation_status?.value
                && (<ValidationStatusComponent status={data.validation_status} />)}
            </td>
            <td aria-label="actions">
              <EOREReportActions
                reportId={data.id}
                setLoader={props.setLoader}
                setReportsPageList={props.setReportsPageList}
                validationStatus={data.validation_status.value}
                isCurrentValidationRole={data.is_current_validation_role}
              />
            </td>
          </TableRow>
        ))}
        {props.next && (
          <div className="loadMore-div">
            <button
              className="loadMore-button"
              onClick={() => {
                props.setReportsPageCount(props.reportsPageCount + 1);
              }}
            >
              Load more...
            </button>
          </div>
        )}
      </tbody>
    </Table>
  </div>
);

export const EOREReportActions = (props) => {
  const [disabledDeleteDialogs, setDisabledDeleteDialogs] = useState({});
  const [disabledButtons, setDisabledButtons] = useState({});
  const { user } = useUser();

  const handleContextMenu = (e) => {
    e.preventDefault(); 
   }

  const handleDownloadEOREDocxBtn = (itemId) => {
    exportEOREDocx(itemId);
  };

  const handleAuxClick = (event, id) => {
    if (event.button === 1) {
      handleClick(id); 
    }
  };

  const handleClick = (id) => {
    setDisabledButtons(prevState => ({
      ...prevState,
      [id]: { validation: true, edit: true, delete: true  }
    }));
    setDisabledDeleteDialogs(prevState => ({
      ...prevState,
      [id]: true
    }));
  };

  const deleteReport = async (reportId) => {
    props.setLoader(true);
    deleteEoreReport(reportId)
      .then((res) => {
        if (res.status === 204) {
          toast.success('Report deleted successfully');
          props.setReportsPageList((prevReports) => prevReports
            .filter((report) => report.id !== reportId));
          props.setLoader(false);
        }
      })
      .catch((error) => {
        toast.error('Failed to delete report');
        console.error(error);
        props.setLoader(false);
      });
  };
  const draftOrRejectedStatus = props?.validationStatus === 'draft'
    || props?.validationStatus === 'draft_rejected';
  const validationStatus = props?.validationStatus?.startsWith('val_')
    || props?.validationStatus === 'imsma_rejected';
   
    const isEditDisabled = disabledButtons[props.reportId]?.edit;
    const isValidationDisabled = disabledButtons[props.reportId]?.validation;

  return (
    <div className="all-icons" onContextMenu={handleContextMenu}>
      {user?.permissions?.view_eore_report && (
         <Link
           to={`/reports/eore/view/${props.reportId}`}
           target="_blank" rel="noopener noreferrer" 
           className="view-icon"
         >
          <ViewSvg />
         </Link>
      )}
      {user?.permissions?.change_eore_report
        && draftOrRejectedStatus
        && (
          <Link
            to={`/reports/eore/edit/${props.reportId}`}
            target="_blank" rel="noopener noreferrer" 
            className="edit-icon"
            style={{ color: isEditDisabled ? '#d3d3d3' : '#868686', pointerEvents: isEditDisabled ? 'none' : 'auto' }}
            onClick={() => handleClick(props?.reportId)}
            onAuxClick={(event) => handleAuxClick(event, props?.reportId)}
          >
            <PencilSvg />
          </Link>
        )}
      {user?.permissions?.validate_eore_report
        && validationStatus
        && props.isCurrentValidationRole
        && (
          <Link
            to={`/reports/eore/validation/${props.reportId}`}
            target="_blank" rel="noopener noreferrer" 
            className="view-icon"
            style={{ color: isValidationDisabled ? '#d3d3d3' : '#868686', pointerEvents: isValidationDisabled ? 'none' : 'auto' }}
            onClick={() => handleClick(props?.reportId)}
            onAuxClick={(event) => handleAuxClick(event, props?.reportId)}
          >
            <CommentIcon />
          </Link>
        )}
      {user?.permissions?.delete_eore_report
        && draftOrRejectedStatus
        && (
          <DeleteReportDialog
            deleteEoreReport={() => deleteReport(props.reportId)}
            isDisabled={disabledDeleteDialogs[props.reportId]}
          />
        )}
      <div className="import-and-export-actions">
        <ExportIcon
          className="export-icon"
          onClick={() => handleDownloadEOREDocxBtn(props.reportId)}
        />
      </div>
    </div>
  );
};
