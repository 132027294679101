import React from 'react';
import { AccordionBodyItem } from 'component/Accordion';
import { DatePickerWrapper } from 'component/DataPicker';
import moment from 'moment';
import { ButtonComponent } from 'component/Button';
import { ReactComponent as SaveSvg } from 'assets/images/icons/save-1.svg';
import { updateEoreReportById } from 'api/EOREApi';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

export const ReportDate = (props) => {
  const { formData } = props;

  const date = formData?.info?.general?.report_date;

  const updateReportDate = (value) => {
    props.setFormData((prevFormData) => ({
      ...prevFormData,
      info: {
        ...prevFormData.info,
        general: {
          ...prevFormData.info.general,
          report_date: moment(value, 'DD.MM.YYYY').format('L'),
        },
      },
    }));
  };

  return (
    <AccordionBodyItem
      accordionSubId={(
        <div>
          <span>1.4</span>
          <span className="star">*</span>
        </div>
      )}
      titleUA="Дата звіту"
      titleEN="Report date"
      className={props.errors?.general?.report_date ? 'error' : ''}
      children={(
        <DatePickerWrapper
          value={date ? moment(date, 'DD.MM.YYYY') : null}
          handleDatePicker={updateReportDate}
        />
      )}
    />
  );
};

export const ReportDateString = (props) => {
  const { formData } = props;

  const date = formData?.info?.general?.report_date;

  return (
    <AccordionBodyItem
      accordionSubId={(
        <div>
          <span>1.4</span>
          <span className="star">*</span>
        </div>
      )}
      titleUA="Дата звіту"
      titleEN="Report date"
      className={props.errors?.general?.report_date ? 'error' : ''}
      children={(
        <div>
          {date}
        </div>
      )}
    />
  );
}

export const ReportDateWithSaveButton = (props) => {
  const { formData } = props;
  const { reportId } = useParams();

  const date = formData?.info?.general?.report_date;

  const updateReportDate = (value) => {
    props.setFormData((prevFormData) => ({
      ...prevFormData,
      info: {
        ...prevFormData.info,
        general: {
          ...prevFormData.info.general,
          report_date: moment(value, 'DD.MM.YYYY').format('L'),
        },
      },
    }));
  };

  const handleSaveButton = async () => {
    try {
      const formattedDate = moment(date, 'DD.MM.YYYY').format('DD.MM.YYYY');
      const body = {
        info: {
          general: {
            report_date: formattedDate,
          },
        },
      };

      await updateEoreReportById(reportId, body);

      toast.success('Report updated');
    } catch (error) {
      toast.error('Error with updating report date');
    }
  };

  return (
    <AccordionBodyItem
      accordionSubId={(
        <div>
          <span>1.4</span>
          <span className="star">*</span>
        </div>
      )}
      titleUA="Дата звіту"
      titleEN="Report date"
      className={props.errors?.general?.report_date ? 'error' : ''}
      children={(
        <div
          className='flex-column-with-save-button'
        >
          <DatePickerWrapper
            value={date ? moment(date, 'DD.MM.YYYY') : null}
            handleDatePicker={updateReportDate}
          />
          <ButtonComponent
            iconLeft={<SaveSvg />}
            handleButton={handleSaveButton}
            label="Save"
          />
        </div>
      )}
    />
  );
};

