export const NTSTemplate = {
  general: {
    hazard_name: false,
    imsma_id: false,
    report_date: false,
    reporter_organization: false,
    reporter_name: false,
    reporter_occupation: false,
    non_tech_examination_date: false,
    team_id: false,
    donor: false,
  },
  location: {
    region: false,
    district: false,
    local_community: false,
    settlement_name: false,
    settlement_code: false,
    description: false,
  },
  nto_result: {
    village_by_village: false,
    nto_reason: false,
    evidences: false,
    single_ammo_report_needed: false,
    new_hazard_area_report_needed: false,
    comments: false,
  },
  point: false,
};

export const HATemplate = {
  lm: false,
  bm: false,
  turn_points: false,
  general: {
    internal_id: false,
    hazard_name: false,
    imsma_id: false,
    report_date: false,
    reporter_organization: false,
    reporter_name: false,
    team_id: false,
    reporter_occupation: false,
    donor: false,
    examination_date: {
      from: false,
      to: false,
    },
    hazard_area_type: false,
    hazard_type: false,
  },
  location: {
    region: false,
    district: false,
    local_community: false,
    settlement_name: false,
    settlement_code: false,
    nearest_settlement_name: false,
  },
  hazard_type: {
    anti_personnel_mines: false,
    anti_transport_mines: false,
    unexploded_ordnance: false,
    abandoned_explosive_ordnance: false,
    cluster_munition: false,
    improvised_explosive_device: false,
    small_arms_ammunition: false,
    explosives: false,
    description: false,
  },
  evidences: {
    direct_evidences: {
      evidence_type: false,
      long: false,
      lat: false,
      description: false,
      direct_evidences_description: false,
    },
    indirect_evidences: {
      description: false,
      evidence_type: false,
      long: false,
      lat: false,
    },
  },
  polygon_info: {
    polygon_count: false,
    coordinate_system: false,
    zone_number: false,
  },
  geographic_and_terrain_description: {
    vegetation: false,
    relief: false,
    slope_gradient: false,
    seasonal_accessibility: false,
    current_field_marking: false,
    current_field_marking_other: false,
  },
  next_landrelease_activity: {
    priority: false,
    next_most_appropriate_operation: false,
    reasons_and_comments_on_71_72: false,
    reasons_and_comments_on_71_72_extended: false,
    recommended_clearance_depth: false,
    additional_comments_on_hazard_area: false,
    additional_comments_on_hazard_area_extended: false,
    recommended_clearance_depth_description: false,
  },
  landuse_and_beneficiaries: {
    residental_areas: false,
    social_services: false,
    infrastructure_industrial_objects: false,
    roads_railways_bridges: false,
    agricultural: false,
    natural_resources: false,
    number_of_direct_beneficiaries: {
      men: false,
      women: false,
      boys: false,
      girls: false,
    },
    number_of_indirect_beneficiaries: {
      men: false,
      women: false,
      boys: false,
      girls: false,
    },
  },
  informators: {
    name: false,
    position: false,
    age: false,
    sex: false,
    phone: false,
    address: false,
  },
};

export const HABlocks = {
  general: false,
  examination_date: false,
  location: false,
  hazard_type: false,
  evidences: false,
  direct_evidences: false,
  indirect_evidences: false,
  polygon_info: false,
  geographic_and_terrain_description: false,
  next_landrelease_activity: false,
  landuse_and_beneficiaries: false,
  number_of_direct_beneficiaries: false,
  number_of_indirect_beneficiaries: false,
  informators: false,
};

export const NTSBlocks = {
  general: false,
  location: false,
  nto_result: false,
};

export const EOREBlocks = {
  general: false,
  session_information: false,
  direct_beneficiaries: false,
  indirect_beneficiaries: false,
  contact_person: false,
  final_indicators: false,
}

export const EORETemplate = {
  general: {
    imsma_id: false,
    internal_id: false,
    report_date: false,
    reporter_organization: false,
    reporter_name: false,
    reporter_position: false,
    team_id: false,
    donor: false,
  },
  session_information: {
    region: false,
    district: false,
    local_community: false,
    settlement_name: false,
    settlement_code: false,
    eore_date: false,
    eore_type_internal: false,
    eore_type_imsma: false,
    place_type_internal: false,
    place_type_imsma: false,
    description_of_place_type: false,
    direct_beneficiaries: {
      female_adult_direct_count: false,
      male_adult_direct_count: false,
      female_child_direct_count: false,
      male_child_direct_count: false,
      handouts: false,
    },
    indirect_beneficiaries: {
      female_adult_direct_additional_count: false,
      male_adult_direct_additional_count: false,
      female_child_direct_additional_count: false,
      male_child_direct_additional_count: false,
      indirect_handouts: false,
    },
    number_of_sessions: false,
    contact_person: {
      name: false,
      phone_number: false,
    },
  },
  final_indicators: {
    percentage_before_session: false,
    percentage_after_session: false,
  },
  eore_point: false
}