import React, { useEffect, useState } from 'react';
import { AccordionHeaderUserSettings } from 'component/Accordion';
import { InputComponent } from 'component/Input';
import {
  AccordionItem, AccordionItemButton, AccordionItemHeading, AccordionItemPanel,
} from 'react-accessible-accordion';
import { ButtonComponent } from 'component/Button';
import { ReactComponent as SaveIcon } from 'assets/images/icons/save-1.svg';
import { ReactComponent as DeleteIcon } from 'assets/images/icons/delete_24px.svg';
import { ReactComponent as AddIcon } from 'assets/images/icons/add_24px.svg';

import { getAllUsersForEOREDetails, getUserById } from 'api/UserAPI';
import { ClipLoader } from 'react-spinners';
import { SelectComponent } from 'component/Select';
import { UserLabel } from 'pages/Profile/Notifications/Table';
import { toast } from 'react-toastify';

import { deleteHandoutById, createOrUpdateEoreHandouts, deleteAllUserHandouts } from 'api/EOREApi';
import { DeleteAllHandoutsDialog } from 'component/Dialog';

const ReportRowDetails = ({ setLoader, refreshData, userContext }) => {
  const [usersWithHandouts, setUsersWithHandouts] = useState([]);
  const [activeAccordionId, setActiveAccordionId] = useState(null);
  const [userData, setUserData] = useState({});
  const [inputValues, setInputValues] = useState({});
  const [deletedHandouts, setDeletedHandouts] = useState({});
  const [loadingHandoutsById, setLoadingHandoutsById] = useState({});
  const [userSettings, setUserSettings] = useState({});
  const [allUsersWithoutHandouts, setAllUsersWithoutHandouts] = useState([]);

  const fetchAllUsersForEOREDetails = async () => {
    setLoader(true);
    try {
      const res = await getAllUsersForEOREDetails(true);
      setUsersWithHandouts(res.data);
      await getAllUsersForEOREDetails(false).then((result) => {
        setAllUsersWithoutHandouts(result.data);
      });
    } catch (error) {
      console.error('Error fetching users:', error);
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    fetchAllUsersForEOREDetails();
  }, [refreshData]);

  const handleAccordionToggle = (id) => {
    setActiveAccordionId(id);
    if (!userData[id]) {
      setLoadingHandoutsById((prev) => ({ ...prev, [id]: true }));
      getUserById(id).then((res) => {
        setUserData((prev) => ({
          ...prev,
          [id]: res.data,
        }));
        const initialValues = res.data.handouts.reduce((acc, handout) => {
          acc[handout.id] = handout.name;
          return acc;
        }, {});
        setInputValues((prevValues) => ({
          ...prevValues,
          [id]: initialValues,
        }));
        setLoadingHandoutsById((prev) => ({ ...prev, [id]: false }));
      });
    }
  };

  const handleInputChange = (userId, handoutId, value) => {
    setInputValues((prevValues) => ({
      ...prevValues,
      [userId]: {
        ...prevValues[userId],
        [handoutId]: value,
      },
    }));
  };

  const handleAddHandout = (userId) => {
    const newHandoutId = `new-${Date.now()}`;
    setInputValues((prevValues) => ({
      ...prevValues,
      [userId]: {
        ...prevValues[userId],
        [newHandoutId]: '',
      },
    }));
    setUserData((prevData) => ({
      ...prevData,
      [userId]: {
        ...prevData[userId],
        handouts: [
          ...(prevData[userId]?.handouts || []),
          { id: newHandoutId, name: '' },
        ],
      },
    }));
  };

  const handleDeleteHandout = async (userId, handoutId) => {
    const handoutIdStr = handoutId.toString();
    if (!handoutIdStr.startsWith('new-')) {
      try {
        setLoader(true);
        await deleteHandoutById(handoutId);
        toast.success('Handout deleted successfully');
      } catch (error) {
        console.error('Error deleting handout:', error);
        toast.error('Failed to delete handout');
        return;
      } finally {
        setLoader(false);
      }
    }

    setInputValues((prevValues) => {
      const { [handoutId]: _, ...rest } = prevValues[userId];
      return {
        ...prevValues,
        [userId]: rest,
      };
    });

    setDeletedHandouts((prevValues) => ({
      ...prevValues,
      [userId]: {
        ...prevValues[userId],
        [handoutId]: true,
      },
    }));

    setUserData((prevData) => ({
      ...prevData,
      [userId]: {
        ...prevData[userId],
        handouts: prevData[userId].handouts.filter(
          (handout) => handout.id !== handoutId,
        ),
      },
    }));
  };

  const handleSave = async () => {
    const userId = activeAccordionId;
    const selectedUser = userSettings[userId]?.selectedUser;
    const selectedUserId = selectedUser ? selectedUser.value : userId;

    const payload = Object.keys(inputValues[userId]).map((handoutId) => {
      const handout = inputValues[userId][handoutId];
      if (deletedHandouts[userId] && deletedHandouts[userId][handoutId]) {
        return {
          id: handoutId,
          name: '',
          user: selectedUserId,
        };
      }
      if (handoutId.startsWith('new-')) {
        return {
          name: handout,
          user: selectedUserId,
        };
      }
      return {
        id: handoutId,
        name: handout,
        user: selectedUserId,
      };
    });

    try {
      setLoader(true);
      await createOrUpdateEoreHandouts(payload);
      toast.success('Handouts saved successfully');
      setDeletedHandouts({});
      await fetchAllUsersForEOREDetails();
    } catch (error) {
      console.error('Error saving data:', error);
      toast.error('Failed to save handouts');
    } finally {
      setLoader(false);
    }
  };

  const convertObjectToArrayUsers = (usersWithoutHandouts) => usersWithoutHandouts?.map((user) => {
    const transformUserToLabel = {
      email: user.email,
      id: user.id,
      name: user.name,
      surname: user.surname,
      short_role_name: user.role.short_name,
      role_color: user.role.color,
    };

    return {
      value: user.id,
      label: <UserLabel user={transformUserToLabel} />,
    };
  });

  const handleSelectChange = (userId, newUser) => {
    setUserSettings((prevSettings) => ({
      ...prevSettings,
      [userId]: {
        ...prevSettings[userId],
        selectedUser: newUser,
      },
    }));
  };

  const handleDeleteAllHandouts = async (userId) => {
    try {
      setLoader(true);
      await deleteAllUserHandouts(userId);
      toast.success('All handouts deleted successfully');
      await fetchAllUsersForEOREDetails();
    } catch (error) {
      console.error('Error deleting all handouts:', error);
      toast.error('Failed to delete all handouts');
    } finally {
      setLoader(false);
    }
  };

  return (
    usersWithHandouts?.map((user) => (
      <AccordionItem key={user.id} uuid={user.id} onClick={() => handleAccordionToggle(user.id)}>
        <AccordionItemHeading>
          <AccordionItemButton>
            <AccordionHeaderUserSettings
              title={user.role.short_name}
              userName={user.name}
              email={user.email}
              backgroundColor={user.role.color}
            />
          </AccordionItemButton>
        </AccordionItemHeading>
        <AccordionItemPanel className="accordion-validation-panel">
          {loadingHandoutsById[user.id] ? (
            <div className="block-with-cliploader">
              <ClipLoader
                color="rgba(0, 177, 183, 1)"
                size={20}
                aria-label="Loading Spinner"
                data-testid="loader"
              />
            </div>
          ) : (
            <>
              <span className="validation-flow-title" />
              <div
                className="handouts-wrapper"
              >
                {userData[user.id]?.handouts?.map((handout) => (
                  <div
                    key={handout.id}
                    className="handout-item"
                  >
                    <InputComponent
                      classNameWrapper="w-100"
                      style={{ width: '-webkit-fill-available' }}
                      type="text"
                      id={`input-handout-${handout.id}`}
                      name={`handout-name-${handout.id}`}
                      value={inputValues[user.id]?.[handout.id] || ''}
                      handleInput={(event) => handleInputChange(user.id, handout.id, event)}
                    />
                    {
                      (userContext?.permissions?.crud_handouts_self
                        || userContext?.permissions?.crud_handouts_superuser)
                      && (
                        <DeleteIcon
                          className="delete-icon"
                          style={{ color: '#868686' }}
                          onClick={() => handleDeleteHandout(user.id, handout.id)}
                        />
                      )}
                  </div>
                ))}
              </div>
              <div
                className="add-handout-wrapper"
              >
                {
                  (userContext?.permissions?.crud_handouts_self
                    || userContext?.permissions?.crud_handouts_superuser)
                  && (
                    <ButtonComponent
                      iconLeft={<AddIcon />}
                      label="Add"
                      handleButton={() => handleAddHandout(user.id)}
                    />
                  )}
                <div
                  className="select-wrapper"
                  style={{
                    display: 'flex',
                    marginBottom: '20px',
                  }}
                >
                  <span className="label">User</span>
                  <SelectComponent
                    id="users"
                    name="users"
                    label="User"
                    value={userSettings[user.id]?.selectedUser || null}
                    options={convertObjectToArrayUsers(allUsersWithoutHandouts)}
                    handleSelect={(value, label) => handleSelectChange(user.id, { value, label })}
                    placeholder={(
                      userSettings[user.id]?.selectedUser ? (
                        <UserLabel user={userSettings[user.id].selectedUser.user} />
                      ) : (
                        <UserLabel user={user} />
                      )
                    )}
                  />
                </div>
              </div>
              {
                (userContext?.permissions?.crud_handouts_self
                  || userContext?.permissions?.crud_handouts_superuser)
                && (
                  <>
                    <div
                      className="line"
                      style={{
                        marginBottom: '20px',
                      }}
                    />
                    <div className="buttons-group-end">
                      <DeleteAllHandoutsDialog
                        handleDeleteAllHandouts={handleDeleteAllHandouts}
                        user={user}
                      />
                      <ButtonComponent
                        iconLeft={<SaveIcon />}
                        label="Save"
                        handleButton={handleSave}
                      />
                    </div>
                  </>
                )}
            </>
          )}
        </AccordionItemPanel>
      </AccordionItem>
    ))
  );
};

export default ReportRowDetails;
