import React, { useEffect, useState } from 'react';
import { Accordion } from 'react-accessible-accordion';
import { Loader } from 'component/Loader';
import { ToastContainer } from 'react-toastify';
import { useNavigate, useParams } from 'react-router-dom';
import { getEoreReportById, getHandouts } from 'api/EOREApi';
import { EOREFormData } from 'consts/Forms';
import { FinalIndicatorsView } from './Blocks/View/FinalIndicators/FinalIndicators';
import { SessionInfo } from './Blocks/View/SessionInfo/SessionInfo';
import { PageTitle } from './Create';
import { getAttachments, getEorePhotos } from './Blocks/PhotosBlock';
import GeneralInfo from './Blocks/View/GeneralInfo/GeneralInfo';
import PhotosBlockView from './Blocks/View/PhotosBlockView/PhotosBlockView';
import AttachmentsBlockView from './Blocks/View/AttachmentsBlockView/AttachmentsBlockView';

export const ViewEOREReportPage = () => {
  const [loader, setLoader] = useState(true);
  const [formData, setFormData] = useState(EOREFormData);
  const [templateVersion, setTemplateVersion] = useState('');
  const [handouts, setHandouts] = useState([]);
  const [photos, setPhotos] = useState([]);
  const [attachments, setAttachments] = useState([]);
  const [zone, setZone] = useState('');
  const { reportId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    getEoreReportById(reportId)
      .then((res) => {
        setFormData(res.data);
        getEorePhotos(res.data.id, res.data.info.general_photos, setPhotos);
        getAttachments(res.data, setAttachments);
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setLoader(false);
      });
  }, []);

  useEffect(() => {
    setTemplateVersion(formData.template_version);
  }, [formData.template_version]);

  useEffect(() => {
    getHandouts()
      .then((res) => {
        setHandouts(res.data?.map((handout) => ({
          handout_id: handout.id,
          name: handout.name,
          count: 0,
        })));
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  return (
    <section>
      <PageTitle
        title="View EORE report"
        formData={formData}
        templateVersion={templateVersion}
        setLoader={setLoader}
      />
      <div className="info_block_header">
        <div className="info_block_header_left">Атрибути</div>
        <span className="info_block_header_line" />
        <div className="info_block_header_right">Attributes</div>
      </div>
      {/* <TranslateWrapperComponent
      formData={formData}
    // setTranslatedFormData={setTranslatedFormData}
    // translationState={translateState}
    > */}
      <div className="form-wrapper">
        <Accordion
          allowMultipleExpanded
          allowZeroExpanded
          preExpanded={[
            'general',
            'sessionInformation',
            'finalIndicators',
          ]}
          className="custom-accordion"
        >
          <GeneralInfo
            formData={formData}
            setFormData={setFormData}
          />
          <SessionInfo
            formData={formData}
            handouts={handouts}
            zone={zone}
            setZone={setZone}
            setFormData={setFormData}
          />
          <FinalIndicatorsView
            formData={formData}
          />
          <PhotosBlockView
            formData={formData}
            photos={photos}
            setLoader={setLoader}
          />
          <AttachmentsBlockView
            attachments={attachments}
            setLoader={setLoader}
            formData={formData}
          />
        </Accordion>
      </div>
      {/* </TranslateWrapperComponent> */}
      <Loader visible={loader} />
      <ToastContainer />
    </section>
  );
};
