import React from 'react';
import moment from 'moment';
import { Table, TableHeader, TableRow } from 'component/Table';
import { NewTemplate } from './NewTemplate';
import { ViewTemplate } from './ViewTemplate';
import { DeleteTemplate } from './DeleteTemplate';
import { useUser } from 'routes/UserContext';

export const Templates = (props) => {
  const { user } = useUser();
  return (
    <div className="templates-table flex-column-group">
      <Table>
        <TableHeader>
          <th>Date</th>
          <th>Template name</th>
          <th>Attribute</th>
          <th>Action</th>
        </TableHeader>
        {!props.templates.length
          && (
            <TableRow>
              <td>No data</td>
            </TableRow>
          )}
        {props.templates.map((template, index) => (
          <TableRow key={index}>
            <td>{moment(template.created_at).format('L')}</td>
            <td>{template.name || '-'}</td>
            <td>{template.attributes_count || '-'}</td>
            <td
              className="icons"
              aria-label="icons"
            >
              {user?.permissions?.view_templates && (
                <ViewTemplate
                  template={template}
                  HAAttributes={props.HAAttributes}
                  NTSAttributes={props.NTSAttributes}
                  EoreAttributes={props.EoreAttributes}
                />
              )}
              {user?.permissions?.delete_templates && (
                <DeleteTemplate
                  id={template.id}
                  getTemplates={props.getTemplates}
                  setLoader={props.setLoader}
                />
              )}
            </td>
          </TableRow>
        ))}
        {props.next && (
          <div className="loadMore-div">
            <button
              className="loadMore-button"
              onClick={() => {
                props.setLoader(true);
                props.getTemplates(props.page);
              }}
            >
              Load more...
            </button>
          </div>
        )}
      </Table>
      <div className="donors-footer">
        <NewTemplate
          setLoader={props.setLoader}
          getTemplates={props.getTemplates}
          HAAttributes={props.HAAttributes}
          NTSAttributes={props.NTSAttributes}
          EoreAttributes={props.EoreAttributes}
        />
      </div>
    </div>
  );
};